import React, { Component } from 'react';

// components
import Layout from 'components/common/layout';
import TopSection from 'components/common/page-top-section';
import PartnerList from 'components/partners/partner-list';

// vendor components & lib
import { graphql, StaticQuery } from 'gatsby';

// styles
import 'stylesheets/partners/index.scss';

class PartnersPage extends Component {
  render() {
    const { data } = this.props;
    const backgroundImage = data.backgroundImage.childImageSharp.fluid;
    const categories = (data.allContentfulPartnerCategory || {}).edges || [];
    const featuredPartners = (data.allContentfulPartner || {}).edges || [];
    const featuredPartnersNodes = featuredPartners.map(
      partner => (partner || {}).node,
    );

    return (
      <Layout title="Partners">
        <main className="partners-page">
          <TopSection image={backgroundImage} title="Partners" />
          <div className="partners-page_content">
            <PartnerList
              title="Featured Partners"
              partners={featuredPartnersNodes}
              boldTitle
            />

            {categories.map(category => {
              const { id, partner: partnersList, title } =
                (category || {}).node || {};

              return (
                <PartnerList key={id} title={title} partners={partnersList} />
              );
            })}
          </div>
        </main>
      </Layout>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulPartner(filter: { featured: { eq: true } }) {
          edges {
            node {
              id
              order
              title
              linkUrl
              shortDescription
              partnerCategory {
                id
                title
              }
              fullDescription {
                childMarkdownRemark {
                  html
                }
              }
              screenshots {
                fluid(maxWidth: 300) {
                  ...GatsbyContentfulFluid
                }
              }
              popular
              color
              image {
                fixed(width: 73) {
                  ...GatsbyContentfulFixed
                }
              }
            }
          }
        }

        allContentfulPartnerCategory {
          edges {
            node {
              id
              title
              partner {
                id
                order

                title
                linkUrl
                shortDescription
                partnerCategory {
                  id
                  title
                }
                fullDescription {
                  childMarkdownRemark {
                    html
                  }
                }
                screenshots {
                  fluid(maxWidth: 300) {
                    ...GatsbyContentfulFluid
                  }
                }
                popular
                color
                image {
                  fixed(width: 73) {
                    ...GatsbyContentfulFixed
                  }
                }
              }
            }
          }
        }
        backgroundImage: file(relativePath: { eq: "partners.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <PartnersPage data={data} {...props} />}
  />
);
