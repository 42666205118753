import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import memoize from 'lodash.memoize';

// components
import PartnerItem from 'components/partners/partner-item';

class PartnerList extends Component {
  static propTypes = {
    title: PropTypes.string,
    partners: PropTypes.array,
    boldTitle: PropTypes.bool,
  };

  getSortedPartners = memoize(partners =>
    partners.sort((a, b) => ~~a.order - ~~b.order),
  );

  render() {
    const { title, partners, boldTitle } = this.props;

    const titleClassNames = classNames('partners-page_list_title', {
      'partners-page_list_title--bold': boldTitle,
    });

    if (!(partners || {}).length) {
      return null;
    }

    const sortedParners = this.getSortedPartners(partners);

    return (
      <div className="partners-page_list">
        <h3 className={titleClassNames}>{title}</h3>
        <div className="d-flex flex-column flex-md-wrap align-items-md-start flex-md-row ">
          {sortedParners.map(partner => (
            <PartnerItem key={partner.id} partner={partner} />
          ))}
        </div>
      </div>
    );
  }
}

export default PartnerList;
