import React from 'react';
import PropTypes from 'prop-types';

// components
import Modal from 'components/common/modal';
import Button from 'components/common/button';

// vendor components
import Img from 'gatsby-image';

// styles
import './partner-modal.scss';

function PartnerModal({ partner, onClose }) {
  const {
    title,
    fullDescription,
    partnerCategory,
    linkUrl,
    screenshots,
    color,
    image,
  } = partner || {};

  const hasScreensots = !!(screenshots || []).length;
  const description = ((fullDescription || {}).childMarkdownRemark || {}).html;
  const logoImage = (image || {}).fixed;

  const borderStyles = {
    backgroundColor: color,
  };

  return (
    <Modal
      contentClassName="partners-page_modal_content"
      bodyClassName="partners-page_modal_body"
      closeBtnClassName="partners-page_modal_close-btn"
      headerClassName="partners-page_modal_header"
      className="partners-page_modal"
      dialogClassName="partners-page_modal_dialog"
      footerClassName="d-none"
      headerContent={
        <ModalHeader
          title={title}
          color={color}
          image={logoImage}
          linkUrl={linkUrl}
          categories={partnerCategory}
        />
      }
      onClose={onClose}
      open
    >
      <div className="d-flex flex-column-reverse flex-sm-row">
        <div className="partners-page_modal_description-wrap">
          <h1>Overview</h1>
          <div
            className="partners-page_modal_description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>

        {hasScreensots && (
          <div className="partners-page_modal_screenshots-wrap">
            {(screenshots || []).map((screenshot, index) => (
              <PartnerScreenshot key={index} screenshot={screenshot} />
            ))}
          </div>
        )}
      </div>
      <div style={borderStyles} className="partners-page_modal_border" />
    </Modal>
  );
}

function ModalHeader({ color, image, title, linkUrl, categories }) {
  const headerStyles = {
    backgroundColor: color,
  };

  const categoriesNames = categories
    ? categories.map(({ title }) => title).join(', ')
    : '';

  return (
    <div style={headerStyles} className="partners-page_modal_header_content">
      <div className="partners-page_item_image-wrap partners-page_item_image-wrap--shadow">
        {image && <Img fixed={image} className="partners-page_item_image" />}
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center align-items-sm-start">
        <h4 className="partners-page_item_title">{title}</h4>
        <div className="partners-page_modal_categories">{categoriesNames}</div>
      </div>
      <Button
        to={linkUrl}
        className="partners-page_modal_site-link"
        target="_blank"
        isExternalLink
        useLink
      >
        Visit Website
      </Button>
    </div>
  );
}

function PartnerScreenshot({ screenshot }) {
  const image = (screenshot || {}).fluid;

  return <Img className="partners-page_modal_screenshot" fluid={image} />;
}

PartnerModal.propTypes = {
  partner: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

export default PartnerModal;
