import React, { Component } from 'react';
import PropTypes from 'prop-types';

// component
import PartnerModal from 'components/partners/partner-modal';

// vendor components
import Img from 'gatsby-image';

class PartnerItem extends Component {
  static propTypes = {
    partner: PropTypes.object,
  };

  state = {
    isModalOpen: false,
  };

  handleClick = () => {
    this.setState({
      isModalOpen: true,
    });
  };

  handleModalClose = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  render() {
    const { partner } = this.props;
    const { title, shortDescription, color, image, popular } = partner || {};
    const { isModalOpen } = this.state;

    const logoImage = (image || {}).fixed;

    const borderStyles = {
      backgroundColor: color,
    };

    return (
      <>
        <div className="partners-page_item">
          {isModalOpen && (
            <PartnerModal onClose={this.handleModalClose} partner={partner} />
          )}
          <div
            onClick={this.handleClick}
            className="partners-page_item_content"
          >
            {popular && (
              <div className="partners-page_item_popular-badge">
                most popular
              </div>
            )}
            <div style={borderStyles} className="partners-page_item_border" />
            <div className="d-flex flex-row align-items-center">
              <div className="partners-page_item_image-wrap">
                {logoImage && (
                  <Img
                    className="partners-page_item_image"
                    fixed={logoImage}
                    alt={title}
                  />
                )}
              </div>

              <h4 className="partners-page_item_title">{title}</h4>
            </div>
            <p className="partners-page_item_description">{shortDescription}</p>
          </div>
        </div>
      </>
    );
  }
}

export default PartnerItem;
