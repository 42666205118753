import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Header from 'components/common/header';
import ImageAsBackground from 'components/common/image-as-background';

import './page-top-section.scss';

function PageTopSection({
  title,
  description,
  image,
  mobileImage,
  titleClassName,
  descriptionClassName,
  className,
}) {
  const rootClassNames = classNames(className, 'page_top-section');
  const titleClassNames = classNames(titleClassName, 'page_top-section_title');
  const descrClassNames = classNames(
    descriptionClassName,
    'page_top-section_description',
  );

  return (
    <div className={rootClassNames}>
      <Header styleType="dark" showLogo />

      <ImageAsBackground image={image} mobileImage={mobileImage} />

      <div className="page_top-section_content">
        <h1 className={titleClassNames}>{title}</h1>
        <p className={descrClassNames}>{description}</p>
      </div>
      <div className="page_top-section_overlay" />
    </div>
  );
}

PageTopSection.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.object,
  mobileImage: PropTypes.object,
  titleClassName: PropTypes.string,
  descriptionClassName: PropTypes.string,
  className: PropTypes.string,
};

export default PageTopSection;
